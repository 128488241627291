<template>
  <div class="mx-3">
    <h2 class="custom-title-secondary" v-if="isOpening">
      Sabemos que pode parecer cedo, mas você tem a intenção de registrar
      funcionários (CLT) na sua empresa?
    </h2>
    <h2 class="custom-title-secondary" v-else>
      Possui funcionários registrados (CLT) na empresa?
    </h2>
    <selectOptionsCard
      v-model="formUser.hasEmployee"
      :optionsToChoice="choices"
      :isValid="isValid"
      @onAnswer="onAnswer($event)"
      :rules="[(v) => v == true || v == false || 'Escolha uma das opcões']"
    ></selectOptionsCard>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import selectOptionsCard from '@components/registering/selectOptionsCard.vue'

export default {
  components: {
    selectOptionsCard,
  },
  props: {
    answer: {
      type: String,
      default: null,
    },
    isValid: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      currentAnswer: this.answer,
    }
  },
  computed: {
    ...mapState({
      formUser: (state) => state.formDataUser.formUser,
    }),
    ...mapGetters({
      isOpening: 'formDataUser/isOpening',
    }),
    choices() {
      return [
        {
          label: 'Sim',
          value: true,
        },
        {
          label: 'Não',
          value: false,
        },
      ]
    },
  },
  methods: {
    onAnswer(value) {
      this.formUser.hasEmployee = value
      this.$emit('onAnswer', { hasEmployee: this.formUser.hasEmployee })
    },
  },
}
</script>

<style module lang="scss">
@import '@design';
</style>
